<template>
  <div class="container">
    <el-row class="privacy">
      <el-col class="title">服务协议</el-col>
      <el-col class="content"
        >Fusce vehicula dolor arcu, sit amet blandit dolor mollis nec. Donec
        viverra eleifend lacus, vitae ullamcorper metus. Sed sollicitudin ipsum
        quis nunc sollicitudin ultrices. Donec euismod scelerisque ligula.
        Maecenas eu varius risus, eu aliquet arcu. Curabitur fermentum suscipit
        est, tincidunt mattis lorem luctus id. Donec eget massa a diam
        condimentum pretium. Aliquam erat volutpat. Integer ut tincidunt orci.
        Etiam tristique, elit ut consectetur iaculis, metus lectus mattis justo,
        vel mollis eros neque quis augue. Sed lobortis ultrices lacus, a
        placerat metus rutrum sit amet. Aenean ut suscipit justo Fusce vehicula
        dolor arcu, sit amet blandit dolor mollis nec. Donec viverra eleifend
        lacus, vitae ullamcorper metus. Sed sollicitudin ipsum quis nunc
        sollicitudin ultrices. Donec euismod scelerisque ligula. Maecenas eu
        varius risus, eu aliquet arcu. Curabitur fermentum suscipit est,
        tincidunt mattis lorem luctus id. Donec eget massa a diam condimentum
        pretium. Aliquam erat volutpat. Integer ut tincidunt orci. Etiam
        tristique, elit ut consectetur iaculis, metus lectus mattis justo, vel
        mollis eros neque quis augue. Sed lobortis ultrices lacus, a placerat
        metus rutrum sit amet. Aenean ut suscipit justo licitudin ipsum quis
        nunc sollicitudin ultrices. Donec euismod scelerisque ligula. Maecenas
        eu varius risus, eu aliquet arcu. Cur</el-col
      >
    </el-row>
    <el-row class="privacy">
      <el-col class="title">隐私声明</el-col>
      <el-col class="content"
        >Fusce vehicula dolor arcu, sit amet blandit dolor mollis nec. Donec
        viverra eleifend lacus, vitae ullamcorper metus. Sed sollicitudin ipsum
        quis nunc sollicitudin ultrices. Donec euismod scelerisque ligula.
        Maecenas eu varius risus, eu aliquet arcu. Curabitur fermentum suscipit
        est, tincidunt mattis lorem luctus id. Donec eget massa a diam
        condimentum pretium. Aliquam erat volutpat. Integer ut tincidunt orci.
        Etiam tristique, elit ut consectetur iaculis, metus lectus mattis justo,
        vel mollis eros neque quis augue. Sed lobortis ultrices lacus, a
        placerat metus rutrum sit amet. Aenean ut suscipit justo Fusce vehicula
        dolor arcu, sit amet blandit dolor mollis nec. Donec viverra eleifend
        lacus, vitae ullamcorper metus. Sed sollicitudin ipsum quis nunc
        sollicitudin ultrices. Donec euismod scelerisque ligula. Maecenas eu
        varius risus, eu aliquet arcu. Curabitur fermentum suscipit est,
        tincidunt mattis lorem luctus id. Donec eget massa a diam condimentum
        pretium. Aliquam erat volutpat. Integer ut tincidunt orci. Etiam
        tristique, elit ut consectetur iaculis, metus lectus mattis justo, vel
        mollis eros neque quis augue. Sed lobortis ultrices lacus, a placerat
        metus rutrum sit amet. Aenean ut suscipit justo licitudin ipsum quis
        nunc sollicitudin ultrices. Donec euismod scelerisque ligula. Maecenas
        eu varius risus, eu aliquet arcu. Cur</el-col
      >
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },

  created() {},

  methods: {},
}
</script>

<style scoped lang='scss'>
@media screen and (min-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .privacy {
      margin-bottom: 100px;
      width: 61%;
      .title {
        margin: 118px 0 31px 0;
        color: #000000;
        font-size: 36px;
        letter-spacing: 2.25px;
        line-height: 38px;
      }
      .content {
        color: #000000;
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 1.13px;
        line-height: 38px;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .privacy {
      margin-bottom: 52px;
      width: 76%;
      .title {
        margin: 30px 0 19px 0;
        color: #000000;
        font-size: 24px;
        letter-spacing: 1.5px;
        line-height: 38px;
      }
      .content {
        color: #000000;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }
    }
  }
}
</style>
